import { configureStore } from '@reduxjs/toolkit';
import { tsfnaApi } from './api/tsfnaApi';
import tsfnaReducer from './slices/tsfnaSlice';
export const store = configureStore({
  reducer: {
    tsfna: tsfnaReducer,
    [tsfnaApi.reducerPath]: tsfnaApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(tsfnaApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
