import { Box, Stack, Typography } from '@mui/material';
import { getTeamLogo } from '../../gallery/Gallery';
import { MATCH } from '../../redux/api/tsfnaApi';
import { formatDateShort, formatTime } from '../../utils/utils';
import { getMatchResult } from '../admin/adminUtils';
import { TEAM } from './MatchCardContainer';

export interface MatchCardProps {
  match: MATCH;
}

const MatchCard = ({ match }: MatchCardProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: window.innerWidth - 30,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 2,
        borderColor: '#EEEEEE',
        borderRadius: 3,
        maxWidth: 700,
      }}
    >
      <Stack sx={{ width: '70%', height: '100%', padding: 1 }}>
        <TeamCard name={match?.team_a || ''} logo={getTeamLogo(match?.team_a || '')} goals={getMatchResult(match?.result)[0]} />
        <TeamCard name={match?.team_b || ''} logo={getTeamLogo(match?.team_b || '')} goals={getMatchResult(match?.result)[1]} />
      </Stack>
      <Box sx={{ width: '2px', height: '90%', bgcolor: '#EEEEEE' }} />
      <Stack sx={{ width: '30%', height: '100%' }}>
        <MatchTimeContainer
          date={match?.result ? 'FT' : formatDateShort(match?.match_date || '')}
          time={match?.result ? '' : formatTime(match?.match_time || '')}
        />
      </Stack>
    </Stack>
  );
};

export default MatchCard;

export const TeamCard = ({ logo, name, goals }: TEAM) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 1,
      }}
    >
      <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Box
          component="img"
          sx={{
            width: { xs: 20, md: 20, lg: 30, xl: 30 },
            height: { xs: 20, md: 20, lg: 30, xl: 30 },
            mr: 1,
          }}
          src={logo}
        />
        <TextContainer text={name} />
      </Stack>
      {goals && (
        <Typography
          sx={{
            display: 'flex',
            fontSize: { xs: 14, md: 16, lg: 18, xl: 20 },
            fontWeight: 550,
            color: '#808080',
            textDecoration: 'none',
          }}
        >
          {goals}
        </Typography>
      )}
    </Stack>
  );
};

interface MATCH_TIME {
  date: string;
  time: string;
}
export const MatchTimeContainer = ({ date, time }: MATCH_TIME) => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TextContainer text={date} />
      <TextContainer text={time} />
    </Stack>
  );
};

interface TextContainerProps {
  text: string;
}
export const TextContainer = ({ text }: TextContainerProps) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        fontSize: { xs: 12, md: 14, lg: 16, xl: 18 },
        color: '#808080',
        textDecoration: 'none',
      }}
    >
      {text}
    </Typography>
  );
};
