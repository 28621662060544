import { alpha, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomToggle from './CustomToggle';
import ScheduleMatches from './ScheduleMatches';
import { useGetMatchesQuery, useGetTeamStandingsQuery, useGetTopScorersQuery } from '../../redux/api/tsfnaApi';
import Standings from './Standings';
import TopScorers from './TopScorers';
import MatchResults from './MatchResults';
import { useAppSelector } from '../../redux/hooks';
import AdminLogin from './AdminLogin';
import Avatar from '@mui/material/Avatar';
import { Theme } from '../../global-constants/Theme';

export const TABS = {
  SCHEDULE_MATCHES: 'Matches',
  MATCH_RESULTS: 'Results',
  STANDINGS: 'Standings',
  STATS: 'stats',
};

export default function AdminWrapper() {
  // Hooks
  const { user } = useAppSelector(state => state.tsfna);
  const { data: matches, refetch: refetchMatches } = useGetMatchesQuery();
  const { data: standings, refetch: refetchStandings } = useGetTeamStandingsQuery();
  const { data: scorers, refetch: refetchScorers } = useGetTopScorersQuery();
  const [selectedTab, setSelectedTab] = React.useState(TABS.SCHEDULE_MATCHES);
  return user && user.name ? (
    <Box
      sx={{
        width: 'auto',
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        minHeight: window.innerHeight - 150,
        marginBottom: 10,
      }}
    >
      <CustomToggle selected={selectedTab} setSelected={setSelectedTab} />
      {selectedTab === TABS.SCHEDULE_MATCHES && <ScheduleMatches matches={matches} refetchMatches={refetchMatches} />}
      {selectedTab === TABS.MATCH_RESULTS && <MatchResults matches={matches} refetchMatches={refetchMatches} />}
      {selectedTab === TABS.STANDINGS && <Standings standings={standings} refetchStandings={refetchStandings} />}
      {selectedTab === TABS.STATS && <TopScorers scorers={scorers} refetchScorers={refetchScorers} />}
      <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
        <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Hello, <span style={{ fontWeight: 'bold' }}>{user.name}</span>!
          </Typography>
          <Avatar sx={{ bgcolor: alpha(Theme.orange, 500) }} src="https://picsum.photos/200" />
        </Stack>
      </Box>
    </Box>
  ) : (
    <AdminLogin />
  );
}
