import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '../../global-constants/Theme';

interface ImageCardProps {
  src: string;
  title: string;
  descriptions: string;
}
const ImageCard = ({ src, title, descriptions }: ImageCardProps) => {
  return (
    <Box
      sx={{
        width: {
          xs: window.innerWidth - 50,
          sm: window.innerWidth - 50,
          md: window.innerWidth - 50,
          lg: window.innerWidth / 3 - 100,
          xl: window.innerWidth / 3 - 100,
        },
        height: 540,
        boxShadow: 2,
        borderRadius: 1,
        backgroundColor: Theme.orange,
      }}
    >
      <Box
        component="img"
        sx={{
          width: {
            xs: window.innerWidth - 50,
            sm: window.innerWidth - 50,
            md: window.innerWidth - 50,
            lg: window.innerWidth / 3 - 100,
            xl: window.innerWidth / 3 - 100,
          },
          height: 270,
          backgroundColor: 'green',
          borderRadius: 1,
        }}
        src={src}
      ></Box>
      <Box
        sx={{
          width: {
            xs: window.innerWidth - 50,
            sm: window.innerWidth - 50,
            md: window.innerWidth - 50,
            lg: window.innerWidth / 3 - 100,
            xl: window.innerWidth / 3 - 100,
          },
          height: 270,
          borderRadius: 1,
          backgroundColor: Theme.orange,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            height: '30%',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ textTransform: 'capitalize', fontSize: 30, fontWeight: 'bold', color: 'white' }}>{title}</Typography>
        </Box>
        <Box sx={{ height: '70%', width: '100%', pl: 1, pr: 1 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 400, marginTop: 3, color: 'white' }}>{descriptions}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCard;
