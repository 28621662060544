import { MATCH, STANDING, TOP_SCORER } from '../../redux/api/tsfnaApi';

export enum STAGES {
  GROUP_STAGE = 'Group Stage',
  SEMI_FINAL = 'Semi Final',
  THIRD_PLACE = '3rd Place',
  FINAL = 'Final',
}

export const groupMatchesByStage = (matches: MATCH[]) => {
  const groupMatches: { [key: string]: MATCH[] } = {};
  matches.forEach(match => {
    if (groupMatches[match.stage]) {
      groupMatches[match.stage].push(match);
    } else {
      groupMatches[match.stage] = [match];
    }
  });

  const sortedGroupMatches: { [key: string]: MATCH[] } = {};
  Object.keys(STAGES).forEach(stageKey => {
    const stage = STAGES[stageKey as keyof typeof STAGES];
    if (groupMatches[stage]) {
      sortedGroupMatches[stage] = groupMatches[stage];
    }
  });

  return sortedGroupMatches;
};

export const getMatchResult = (result: string | undefined) => {
  let res: [number | undefined, number | undefined] = [undefined, undefined];
  if (result) {
    const arr = result.split(':');
    res = [parseInt(arr[0]), parseInt(arr[1])];
  }
  return res;
};

// sort standings by group
export const sortStandingsByGroup = (standings: STANDING[]) => {
  // Create a shallow copy of the standings array before sorting
  const standingsCopy = [...standings];

  return standingsCopy.sort((a, b) => {
    if (a.group < b.group) {
      return -1;
    }
    if (a.group > b.group) {
      return 1;
    }
    return 0;
  });
};
export const sortStandingsByPointsAndGoals = (teamStandings: STANDING[]) => {
  teamStandings.sort(function (a, b) {
    if (b.points === a.points) {
      return b.goals - a.goals;
    }
    return b.points > a.points ? 1 : -1;
  });

  return teamStandings;
};
export const groupStandingsByGroup = (standings: STANDING[]) => {
  const groupStandings: { [key: string]: STANDING[] } = {};
  standings.forEach(standing => {
    if (groupStandings[standing.group]) {
      groupStandings[standing.group].push(standing);
    } else {
      groupStandings[standing.group] = [standing];
    }
  });

  return groupStandings;
};

export const sortScorers = (players: TOP_SCORER[]) => {
  // Create a shallow copy of the standings array before sorting
  const playersCopy = [...players];

  playersCopy.sort((a, b) => b.goals - a.goals);
  // Return only the top 5 players
  return players;
};
