import { Box, Stack, Typography } from '@mui/material';
import ReactPlayer from 'react-player/youtube';
import { ABOUT_TSFNA } from '../../gallery/tsfna-values/TSFNA_VALUES';
import { Theme } from '../../global-constants/Theme';

interface AboutProps {
  hideTitle?: boolean;
}
const About = ({ hideTitle }: AboutProps) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      spacing={10}
      sx={{
        width: window.innerWidth,
        height: {
          xs: 850,
          sm: 850,
          md: 850,
          lg: 400,
          xl: 400,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
      }}
    >
      <Box
        sx={{
          width: {
            xs: window.innerWidth - 20,
            sm: window.innerWidth - 20,
            md: window.innerWidth - 20,
            lg: window.innerWidth / 2 - 100,
            xl: window.innerWidth / 2 - 100,
          },
          height: 400,
        }}
      >
        {!hideTitle && <AboutTitle />}
        <AboutBody />
      </Box>
      <ImageContainer />
    </Stack>
  );
};

export default About;

export const ImageContainer = () => {
  return (
    <Box
      sx={{
        width: {
          xs: window.innerWidth - 20,
          sm: window.innerWidth - 20,
          md: window.innerWidth - 20,
          lg: window.innerWidth / 2 - 100,
          xl: window.innerWidth / 2 - 100,
        },
        height: 400,
      }}
    >
      <ReactPlayer url="https://www.youtube.com/watch?v=W0HBGZecO38" width="100%" />
    </Box>
  );
};

export const AboutBody = () => {
  return (
    <Typography component="div" align="center">
      <Box sx={{ fontSize: 18, fontWeight: 'normal', color: '#666666', marginTop: 3 }}>{ABOUT_TSFNA}</Box>
    </Typography>
  );
};

export const AboutTitle = () => {
  return (
    <Typography component="div" align="center">
      <Box sx={{ textTransform: 'capitalize', fontSize: 30, fontWeight: 'bold', color: Theme.mainBg }}>About TSFNA</Box>
    </Typography>
  );
};
