import { STAGES } from '../redux/api/tsfnaApi';
import { HEADER_TITLES, TOURNAMENT_LIST } from './Enums';

export const LEFT_HEADER_TITLE = [];
export const RIGHT_HEADER_TITLE = [
  HEADER_TITLES.HOME,
  HEADER_TITLES.TOURNAMENT,
  HEADER_TITLES.GALLERY,
  HEADER_TITLES.ABOUT,
  HEADER_TITLES.CONTACT_US,
  HEADER_TITLES.REGISTER,
];

export const TOURNAMENT_CATEGORY = [TOURNAMENT_LIST.TOURNAMENT, TOURNAMENT_LIST.TEAMS, TOURNAMENT_LIST.CUP_WINNERS];

export const TEAMS: { value: string; label: string }[] = [
  { value: 'Alula Boston', label: 'Alula  Boston' },
  { value: 'Ayder Minnesota', label: 'Ayder Minnesota' },
  { value: 'Beles Toronto', label: 'Beles Toronto' },
  { value: 'Hayelom San Diego', label: 'Hayelom San Diego' },
  { value: 'Monaliza Dalls', label: 'Monaliza Dalls' },
  { value: 'Remetse Seattle', label: 'Remetse Seattle' },
  { value: 'Segar Houston', label: 'Segar Houston' },
  { value: 'Syum  Maryland', label: 'Syum Maryland' },
  { value: 'TDF DMV', label: 'TDF DMV' },
  // Add more teams here
];

export enum TEAM_NAMES {
  TDF_DMV = 'TDF DMV',
  REMETS_SEATTLE = 'Remetse Seattle',
  AYDER_MINNESOTA = 'Ayder Minnesota',
  HAYELOM_SAN_DIEGO = 'Hayelom San',
  SEGAR_HOUSTON = 'Segar Houston',
  BELES_TORONTO = 'Beles Toronto',
  MONALIZA_DALLAS = 'Monaliza Dalls',
  AYDER_2_MINNESOTA = 'Minnesota-2',
  ALULA_BOSTON = 'Alula Boston',
  SYUM_MARYLAND = 'Syum Maryland',
}

export const TOURNAMENT_STAGES: { value: STAGES; label: STAGES }[] = [
  { value: STAGES.GROUP_STAGE, label: STAGES.GROUP_STAGE },
  { value: STAGES.SEMI_FINAL, label: STAGES.SEMI_FINAL },
  { value: STAGES.THIRD_PLACE, label: STAGES.THIRD_PLACE },
  { value: STAGES.FINAL, label: STAGES.FINAL },
  // Add more stages here
];

// social media links
export const SOCIAL_MEDIAS = {
  FACEBOOK: 'https://www.facebook.com/profile.php?id=100091226424816',
  INSTAGRAM: 'https://instagram.com/tigraysoccer?igshid=YmMyMTA2M2Y=',
  YOUTUBE: 'https://www.youtube.com/@tsfna',
  TIKTOK: 'https://www.tiktok.com/@tsfna0?_t=8mPfYTG18Ri&_r=1',
};
