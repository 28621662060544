import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Theme } from '../../global-constants/Theme';
import { TOP_SCORER, useDeleteTopScorerMutation } from '../../redux/api/tsfnaApi';
import { HEADER_HEIGHT } from '../header/Header';
import ScorerModal from './ScorerModal';

const useStyles = makeStyles({
  root: {
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: alpha(Theme.orange, 0.8),
    },
  },
});

export const COLUMNS = ['Players', 'Team', 'Goals'];

// interfaces
interface MatchResultsProps {
  scorers: TOP_SCORER[] | undefined;
  refetchScorers: () => void;
}

export default function TopScorers({ scorers, refetchScorers }: MatchResultsProps) {
  const width = window.innerWidth > 800 ? 980 : window.innerWidth - 10;

  // Hooks
  const [deleteScorer] = useDeleteTopScorerMutation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [scorerToEdit, setScorerToEdit] = useState<TOP_SCORER | undefined>(undefined);

  return (
    <Box sx={{ width: width, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          marginTop: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ScorerModal player={scorerToEdit} open={open} setOpen={setOpen} refetch={refetchScorers} />
        <TableContainer sx={{ width: '100%', maxHeight: window.innerHeight - HEADER_HEIGHT - 20 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.root}>
                {COLUMNS.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
                <TableCell>
                  {window.innerWidth <= 800 ? (
                    <IconButton
                      aria-label="add"
                      onClick={() => {
                        setOpen(true);
                        setScorerToEdit(undefined);
                      }}
                    >
                      <AddIcon sx={{ color: 'white' }} />
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ color: 'white', borderColor: 'white' }}
                      startIcon={<AddIcon sx={{ color: 'white' }} />}
                      onClick={() => {
                        setOpen(true);
                        setScorerToEdit(undefined);
                      }}
                    >
                      Add Scorer
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scorers && scorers?.length > 0
                ? scorers?.map((scorer, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={scorer.id}
                      sx={{
                        bgcolor: index < 3 ? '#eff7eb' : undefined,
                      }}
                    >
                      {index === 0 ? (
                        <TableCell>{scorer.player} 🥇</TableCell>
                      ) : index === 1 ? (
                        <TableCell>{scorer.player} 🥈</TableCell>
                      ) : index === 2 ? (
                        <TableCell>{scorer.player} 🥉</TableCell>
                      ) : (
                        <TableCell>{scorer.player}</TableCell>
                      )}
                      <TableCell>{scorer.team}</TableCell>
                      <TableCell>{scorer.goals}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          sx={{ color: '#0D92F4' }}
                          onClick={() => {
                            setScorerToEdit(scorer);
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          sx={{ color: 'red' }}
                          onClick={() => {
                            if (scorer) {
                              deleteScorer(scorer.id).then(() => {
                                refetchScorers();
                              });
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
