import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { USER } from '../api/tsfnaApi';

// Define a type for the slice state
interface InitialStates {
  user: USER;
}

// Define the initial state using that type
const initialState: InitialStates = {
  user: { name: undefined, loggedInTime: '' },
};

export const counterSlice = createSlice({
  name: 'tsfna',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<USER>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = counterSlice.actions;

export default counterSlice.reducer;
