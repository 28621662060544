import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { Theme } from '../../global-constants/Theme';
import { TABS } from './AdminWrapper';

interface TournamentScheduleProps {
  selected: string;
  setSelected: (val: string) => void;
}

const CustomToggle = ({ selected, setSelected }: TournamentScheduleProps) => {
  const width = window.innerWidth > 800 ? 1000 : window.innerWidth;
  const cardWidth = width / 4 - 2;
  return (
    <ToggleButtonGroup
      exclusive
      aria-label="admin dashboard"
      sx={{
        marginTop: 10,
        width: width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ToggleButton
        value={TABS.SCHEDULE_MATCHES}
        selected={selected === TABS.SCHEDULE_MATCHES}
        onClick={() => setSelected(TABS.SCHEDULE_MATCHES)}
        sx={{
          borderRadius: 10,
          width: cardWidth,
          textTransform: 'none',
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        {TABS.SCHEDULE_MATCHES}
      </ToggleButton>
      <ToggleButton
        value={TABS.MATCH_RESULTS}
        selected={selected === TABS.MATCH_RESULTS}
        onClick={() => setSelected(TABS.MATCH_RESULTS)}
        sx={{
          borderRadius: 10,
          width: cardWidth,
          textTransform: 'none',
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        {TABS.MATCH_RESULTS}
      </ToggleButton>
      <ToggleButton
        value={TABS.STANDINGS}
        selected={selected === TABS.STANDINGS}
        onClick={() => setSelected(TABS.STANDINGS)}
        sx={{
          borderRadius: 10,
          width: cardWidth,
          textTransform: 'none',
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        {TABS.STANDINGS}
      </ToggleButton>
      <ToggleButton
        value={TABS.STATS}
        selected={selected === TABS.STATS}
        onClick={() => setSelected(TABS.STATS)}
        sx={{
          borderRadius: 10,
          width: cardWidth,
          textTransform: 'none',
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        {TABS.STATS}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CustomToggle;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#00ff00',
  },
});
