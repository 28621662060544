import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useLoginMutation, USER } from '../../redux/api/tsfnaApi';
import { useAppDispatch } from '../../redux/hooks';
import { setUser } from '../../redux/slices/tsfnaSlice';

//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const modalWidth = window.innerWidth > 800 ? 600 : window.innerWidth - 10;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: modalWidth,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

export default function AdminLogin() {
  // Hooks
  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();
  const [open, setOpen] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValid = () => {
    return username.length > 4 && password.length > 6;
  };

  const reset = () => {
    setUsername('');
    setPassword('');
    setError('');
  };

  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Stack direction="column" spacing={0} sx={style}>
          <Stack direction="column" spacing={5} sx={{ paddingTop: 10 }}>
            <TextField
              error={error === 'Incorrect username'}
              id="outlined-username-input"
              label="username"
              type="text"
              sx={{ width: 300 }}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <FormControl sx={{ width: 300 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'hide the password' : 'display the password'}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={e => setPassword(e.target.value)}
              />
            </FormControl>
          </Stack>

          <Stack direction="column" spacing={1} paddingTop={10}>
            <Button
              variant="contained"
              disabled={!isValid()}
              onClick={() => {
                setError('');
                login({ username, password }).then((res: any) => {
                  const { error, data } = res;
                  if (error && error.data) {
                    setError(error.data.error);
                  } else if (data && data.success) {
                    if (data.name) {
                      const user: USER = {
                        name: data.name,
                        loggedInTime: new Date().toLocaleString(),
                      };
                      dispatch(setUser(user));
                      setOpen(false);
                      reset();
                    }
                  }
                });
              }}
              sx={{ width: 150 }}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
