import { Box, Button, alpha } from '@mui/material';
import React from 'react';
import { Theme } from '../../global-constants/Theme';
import { TOURNAMENT_CATEGORY } from '../../global-constants/constants';
import { useNavigate } from 'react-router-dom';
import { TOURNAMENT_LIST } from '../../global-constants/Enums';
import { HEADER_HEIGHT } from './Header';
interface HoverHeaderProps {
  open: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
}
const HoverHeader = ({ open, setHover }: HoverHeaderProps) => {
  const navigate = useNavigate();

  const onClick = (item: string) => {
    if (item === TOURNAMENT_LIST.TOURNAMENT) {
      navigate('/tournament');
    } else if (item === TOURNAMENT_LIST.TEAMS) {
      navigate('/teams');
    } else if (item === TOURNAMENT_LIST.CUP_WINNERS) {
      navigate('/cup-winners');
    }
    setHover(false);
  };

  return open ? (
    <Box
      sx={{
        width: '60%',
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'fixed',
        backgroundColor: Theme.headerBg,
        top: HEADER_HEIGHT,
        zIndex: 2,
        borderTop: 2,
        borderTopColor: Theme.mainBg,
        cursor: 'pointer',
        border: 0,
        boxShadow: 1,
      }}
      component="button"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {TOURNAMENT_CATEGORY.map((item, index) => (
        <Button
          key={index}
          disableElevation
          disableRipple
          sx={{
            fontSize: 16,
            color: Theme.mainBg,
            fontWeight: '800',
            ':hover': {
              color: alpha(Theme.orange, 0.9),
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => onClick(item)}
        >
          {item}
        </Button>
      ))}
    </Box>
  ) : (
    <></>
  );
};

export default HoverHeader;
