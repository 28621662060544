import { TEAM_NAMES } from '../global-constants/constants';

export interface PHOTO {
  id: number;
  photo: any;
  description: string;
}
export interface TEAM {
  id: number;
  teamName: string;
  photo: any;
}

export interface EXECUTIVE_COMMITTEE {
  id: number;
  name: string;
  role: string;
  photo: any;
}
export const CAROUSEL_PHOTOS: PHOTO[] = [
  { id: -1, photo: require('./last-year-tournament/2023-tournament.png'), description: 'ስዩም-Maryland (2022 DMV)' },
  { id: 0, photo: require('./last-year-tournament/maryland.png'), description: 'ስዩም-Maryland (2022 DMV)' },
  { id: 1, photo: require('./last-year-tournament/dc.jpg'), description: 'TDF-DC (2022 DMV)' },
  { id: 2, photo: require('./last-year-tournament/minnesota.jpg'), description: 'ዓይደር-Minnesota (2022 DMV)' },
  { id: 3, photo: require('./last-year-tournament/dallas.jpg'), description: 'ሞናሊዛ-Dallas (2022 DMV)' },
  { id: 4, photo: require('./last-year-tournament/seattle-2.jpg'), description: 'ረመፅ-Seattle-2 (2022 DMV)' },
  { id: 5, photo: require('./last-year-tournament/san_diego.jpg'), description: 'ሓየሎም/San Diego (2021 Denver)' },
];

export enum TEAM_LOGOS {
  UNKNOWN = require('./team-logos/unknown.png'),
  TDF = require('./team-logos/tdf.JPG'),
  REMETS = require('./team-logos/remets.JPG'),
  HAYELOM = require('./team-logos/hayelo.JPG'),
  SEGAR = require('./team-logos/segar.JPG'),
  AYDER = require('./team-logos/ayder.jpg'),
  BELES = require('./team-logos/toronto.JPG'),
  MONLIZA = require('./team-logos/monaliza.JPG'),
}

export enum US_STATES_FLAGS {
  MARYLAND = require('./team-logos/tabIcon.png'),
  WASHINGTON_DC = require('./states/dc.png'),
  WASHINGTON_STATE = require('./states/seattle-remets.JPG'),
  PORTLAND = require('./states/portland.png'),
  TEXAS = require('./states/texas.png'),
  CALIFORNIA = require('./states/california.png'),
  COLORADO = require('./states/colorado.png'),
  NORTH_CAROLINA = require('./states/northcaroliina.png'),
  MASSACHUSETTS = require('./states/Massachusetts.png'),
  MINNESOTA = require('./states/Minnesota.png'),
}

export const TEAMS: TEAM[] = [
  { id: 0, photo: require('./teams/dallas.jpg'), teamName: 'ሞናሊዛ - Dallas' },
  { id: 1, photo: require('./teams/seattle-2.jpg'), teamName: 'ረመፅ-2 - Seattle' },
  { id: 2, photo: require('./teams/dc.jpg'), teamName: 'TDF - DMV' },
  { id: 3, photo: require('./teams/maryland.png'), teamName: 'ስዩም - Maryland' },
  { id: 4, photo: require('./teams/minnesota.jpg'), teamName: 'ዓይደር - Minnesota' },
  { id: 5, photo: require('./teams/seattle-1.jpg'), teamName: 'ረመፅ-1 - Seattle' },
  { id: 6, photo: require('./teams/boston.jpg'), teamName: 'ኣሉላ - Boston' },
  { id: 7, photo: require('./teams/san_diego.jpg'), teamName: 'ሓየሎም - San Diego' },
  { id: 8, photo: require('./teams/toronto.JPG'), teamName: 'በለስ - Toronto' },
  { id: 8, photo: require('./teams/houston.jpg'), teamName: 'ሰጋር - Houston' },
];

export const CUP_WINNERS: TEAM[] = [
  { id: 3, photo: require('../gallery/cup-winners/cup_winner.png'), teamName: 'ዓይደር - Minnesota | Minnesota | 2024' },
  { id: 3, photo: require('../gallery/cup-winners/cup_winner.png'), teamName: 'ሞናሊዛ - Dallas | Texas | 2023' },
  { id: 2, photo: require('../gallery/cup-winners/2022-seattle.JPG'), teamName: 'ረመፅ - Seattle | DMV | 2022' },
  { id: 1, photo: require('../gallery/cup-winners/cup_winner.png'), teamName: 'ረመፅ - Seattle | Denver CO | 2021' },
];

export const TOURNAMENT_PHOTOS: { [year: string]: PHOTO[] | undefined } = {
  '2022': [
    { id: 0, photo: require('./tournament_photos/2022/image1.jpg'), description: 'DMV 2022' },
    { id: 1, photo: require('./tournament_photos/2022/image2.jpg'), description: 'DMV 2022' },
    { id: 2, photo: require('./tournament_photos/2022/image3.jpg'), description: 'DMV 2022' },
    { id: 3, photo: require('./tournament_photos/2022/image4.jpg'), description: 'DMV 2022' },
    { id: 4, photo: require('./tournament_photos/2022/image5.jpg'), description: 'DMV 2022' },
    { id: 5, photo: require('./tournament_photos/2022/image6.jpg'), description: 'DMV 2022' },
    { id: 6, photo: require('./tournament_photos/2022/image7.jpg'), description: 'DMV 2022' },
    { id: 7, photo: require('./tournament_photos/2022/image8.jpg'), description: 'DMV 2022' },
  ],
  '2023': undefined,
  '2024': [
    { id: 0, photo: require('./tournament_photos/2024/image1.JPG'), description: 'Minneapolis 2024' },
    { id: 1, photo: require('./tournament_photos/2024/image2.JPG'), description: 'Minneapolis 2024' },
    { id: 2, photo: require('./tournament_photos/2024/image3.JPG'), description: 'Minneapolis 2024' },
    { id: 3, photo: require('./tournament_photos/2024/image4.JPG'), description: 'Minneapolis 2024' },
    { id: 4, photo: require('./tournament_photos/2024/image5.JPG'), description: 'Minneapolis 2024' },
    { id: 5, photo: require('./tournament_photos/2024/image6.JPG'), description: 'Minneapolis 2024' },
    { id: 6, photo: require('./tournament_photos/2024/image7.JPG'), description: 'Minneapolis 2024' },
    { id: 7, photo: require('./tournament_photos/2024/image8.JPG'), description: 'Minneapolis 2024' },
    { id: 8, photo: require('./tournament_photos/2024/image9.JPG'), description: 'Minneapolis 2024' },
    { id: 9, photo: require('./tournament_photos/2024/image10.JPG'), description: 'Minneapolis 2024' },
    { id: 10, photo: require('./tournament_photos/2024/image11.JPG'), description: 'Minneapolis 2024' },
    { id: 11, photo: require('./tournament_photos/2024/image12.JPG'), description: 'Minneapolis 2024' },
    { id: 12, photo: require('./tournament_photos/2024/image13.JPG'), description: 'Minneapolis 2024' },
    { id: 13, photo: require('./tournament_photos/2024/image14.JPG'), description: 'Minneapolis 2024' },
  ],
};

export const EXECUTIVE_COMMITTEES: EXECUTIVE_COMMITTEE[] = [
  { id: 0, photo: require('./executive_committees/president.jpeg'), name: 'Haben Redae', role: 'President' },
  { id: 1, photo: require('./executive_committees/secretary.jpg'), name: 'Endalew Gebretsdik', role: 'Secretary' },
  { id: 2, photo: require('./executive_committees/pr.JPG'), name: 'Abduljelil Muhammed Nuru', role: 'Public Relations Officer' },
  {
    id: 3,
    photo: require('./executive_committees/tournament_coo.jpg'),
    name: 'Abreham Teklehaimanot Kahsay',
    role: 'Tournament Coordinator',
  },
  { id: 4, photo: require('./executive_committees/headshot.png'), name: 'Senay Gebremariam', role: 'Treasurer' },
  { id: 5, photo: require('./executive_committees/business_manager.jpg'), name: 'Aklilu', role: 'Bussiness Manager' },
  { id: 6, photo: require('./executive_committees/headshot.png'), name: 'Sisay Woldegebrieal', role: 'Board Advisor' },
  { id: 7, photo: require('./executive_committees/board_advisor.jpg'), name: 'Aman', role: 'Board Advisor' },
];

export const getTeamLogo = (team: string) => {
  switch (team) {
    case TEAM_NAMES.ALULA_BOSTON:
      return TEAM_LOGOS.UNKNOWN;
    case TEAM_NAMES.MONALIZA_DALLAS:
      return TEAM_LOGOS.MONLIZA;
    case TEAM_NAMES.TDF_DMV:
      return TEAM_LOGOS.TDF;
    case TEAM_NAMES.SEGAR_HOUSTON:
      return TEAM_LOGOS.SEGAR;
    case TEAM_NAMES.AYDER_MINNESOTA:
      return TEAM_LOGOS.AYDER;
    case TEAM_NAMES.HAYELOM_SAN_DIEGO:
      return TEAM_LOGOS.HAYELOM;
    case TEAM_NAMES.REMETS_SEATTLE:
      return TEAM_LOGOS.REMETS;
    case TEAM_NAMES.BELES_TORONTO:
      return TEAM_LOGOS.BELES;
    case TEAM_NAMES.SYUM_MARYLAND:
      return TEAM_LOGOS.UNKNOWN;
    default:
      return TEAM_LOGOS.UNKNOWN;
  }
};
