export const formatTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  const suffix = parseInt(hours) >= 12 ? 'PM' : 'AM';
  const formattedHours = parseInt(hours) % 12 || 12;
  return `${formattedHours}:${minutes} ${suffix}`;
};

export const formatDate = (date: string) => {
  return new Date(date + 'T00:00:00Z').toLocaleDateString('en-US', { timeZone: 'UTC', month: 'short', day: 'numeric', year: 'numeric' });
};

// format date to 'Wed, Jun 26'
export const formatDateShort = (date: string) => {
  return new Date(date + 'T00:00:00Z').toLocaleDateString('en-US', { timeZone: 'UTC', weekday: 'short', month: 'short', day: 'numeric' });
};
