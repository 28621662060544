import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { STANDING } from '../../redux/api/tsfnaApi';
import { getTeamLogo } from '../../gallery/Gallery';
import { sortStandingsByPointsAndGoals } from '../admin/adminUtils';

interface TeamStandingProps {
  group: string;
  teamsStanding: STANDING[];
}
export default function TeamStanding({ group, teamsStanding }: TeamStandingProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: window.innerWidth <= 800 ? 380 : 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Table sx={{ width: '100%' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              colSpan={4}
              sx={{
                borderBottom: 'none',
                fontSize: 16,
                color: 'black',
                // fontWeight: 'bold',
                // backgroundColor: alpha(Theme.orange, 0.2),
              }}
            >
              {group}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: 'gray' }}>Team</TableCell>
            <TableCell align="center" sx={{ color: 'gray' }}>
              Played
            </TableCell>
            <TableCell align="center" sx={{ color: 'gray' }}>
              Goals
            </TableCell>
            <TableCell align="center" sx={{ color: 'gray' }}>
              Points
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortStandingsByPointsAndGoals(teamsStanding).map((team: STANDING, index) => (
            <TableRow key={team.team} sx={{ bgcolor: index < 2 ? '#f2f6ef' : undefined, height: '10px' }}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  wordBreak: 'break-word',
                }}
                sx={{
                  fontSize: { xs: 12, md: 14, lg: 16, xl: 18 },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: 18, md: 18, lg: 30, xl: 30 },
                    height: { xs: 18, md: 18, lg: 30, xl: 30 },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'red',
                    marginRight: 2,
                  }}
                  src={getTeamLogo(team.team) as unknown as string}
                />
                {team.team}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: { xs: 12, md: 14, lg: 16, xl: 18 },
                }}
              >
                {team.played}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: { xs: 12, md: 14, lg: 16, xl: 18 },
                }}
              >
                {team.goals}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontSize: { xs: 12, md: 14, lg: 16, xl: 18 },
                }}
              >
                {team.points}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
