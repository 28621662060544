import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutContainer from './components/about/AboutContainer';
import AdminWrapper from './components/admin/AdminWrapper';
import { ScrollToTop } from './components/common/ScrollToTop';
import ContactUsContainer from './components/contact-us/ContactUsContainer';
import CupWinnersContainer from './components/cup-winners/CupWinnersContainer';
import FooterContainer from './components/footer/FooterContainer';
import GalleryContainer from './components/gallery/GalleryContainer';
import Header from './components/header/Header';
import HomeContainer from './components/home/HomeContainer';
import RegisterContainer from './components/register/RegisterContainer';
import TeamsContainer from './components/teams/TeamsContainer';
import TournamentContainer from './components/tournament/TournamentContainer';
import { useState, useEffect } from 'react';

function App() {
  const [currPath, setCurrPath] = useState(window.location.pathname);
  useEffect(() => {
    if (window.location.pathname !== currPath) {
      setCurrPath(window.location.pathname);
    }
  }, [currPath]);

  return (
    <div className="App">
      <BrowserRouter>
        {currPath !== '/admin' && <Header />}
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/tournament" element={<TournamentContainer />} />
          <Route path="/teams" element={<TeamsContainer />} />
          <Route path="/cup-winners" element={<CupWinnersContainer />} />
          <Route path="/gallery" element={<GalleryContainer />} />
          <Route path="/about" element={<AboutContainer />} />
          <Route path="/contact-us" element={<ContactUsContainer />} />
          <Route path="/register" element={<RegisterContainer />} />
          <Route path="/admin" element={<AdminWrapper />} />
        </Routes>
        {currPath !== '/admin' && <FooterContainer />}
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
