import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { EXECUTIVE_COMMITTEES, PHOTO } from '../../gallery/Gallery';
import { Stack, Typography, alpha } from '@mui/material';
import { Theme } from '../../global-constants/Theme';
import { Player } from '@lottiefiles/react-lottie-player';

interface GalleryListProps {
  executives: boolean;
  setImage: (image: any) => void;
  photos?: PHOTO[];
}
export default function GalleryList({ photos, executives, setImage }: GalleryListProps) {
  return (
    <Box sx={{ width: window.innerWidth < 500 ? '100%' : '80%', height: 'auto', overflowY: 'scroll', m: 1 }}>
      {!executives && photos === undefined ? (
        <Stack width="100%" display="flex" alignItems="center" justifyContent="center">
          <Player src={require('../../gallery/lottieFiles/no-photos.json')} loop autoplay style={{ width: 200, height: 200 }} />
          <Typography sx={{ color: 'gray', mr: 3, ml: 3, wordWrap: 'break-word' }}>No photos available for this year. :(</Typography>
        </Stack>
      ) : (
        <ImageList cols={window.innerWidth < 500 ? 2 : 3} gap={10}>
          {executives
            ? EXECUTIVE_COMMITTEES.map(person => (
                <ImageListItem key={person.id} sx={{ background: 'red', boxShadow: 2 }}>
                  <img src={person.photo} alt={person.name} loading="eager" />
                  <ImageListItemBar
                    title={person.name}
                    subtitle={person.role}
                    sx={{
                      backgroundColor: alpha(Theme.orange, 0.5),
                      '& .MuiImageListItemBar-title': {
                        color: Theme.black,
                        fontWeight: 'bold',
                      },
                      '& .MuiImageListItemBar-subtitle': {
                        color: Theme.black,
                      },
                    }}
                  />
                </ImageListItem>
              ))
            : //@ts-ignore
              photos.map(item => (
                <ImageListItem key={item.id}>
                  <img src={item.photo} alt={item.description} loading="eager" onClick={() => setImage(item.photo)} />
                </ImageListItem>
              ))}
        </ImageList>
      )}
    </Box>
  );
}
