import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, Button, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Theme } from '../../global-constants/Theme';
import { MATCH, useDeleteMatchMutation } from '../../redux/api/tsfnaApi';
import { formatDate, formatTime } from '../../utils/utils';
import { HEADER_HEIGHT } from '../header/Header';
import AddEditMatchModal from './AddEditMatchModal';

const useStyles = makeStyles({
  root: {
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: alpha(Theme.orange, 0.8),
    },
  },
});

export const COLUMNS = ['Team A', 'Team B', 'Date', 'Time', 'Stage'];

// interfaces
interface ScheduleMatchesProps {
  matches: MATCH[] | undefined;
  refetchMatches: () => void;
}

const cellFont = { xs: 8, md: 10, lg: 14, xl: 18 };

export default function ScheduleMatches({ matches, refetchMatches }: ScheduleMatchesProps) {
  const width = window.innerWidth > 800 ? 980 : window.innerWidth - 10;

  // Hooks
  const [deleteMatch] = useDeleteMatchMutation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [matchToEdit, setMatchToEdit] = useState<MATCH | undefined>(undefined);
  return (
    <Box
      sx={{
        width: width,
        height: '100%',
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AddEditMatchModal match={matchToEdit} open={open} setOpen={setOpen} refetch={refetchMatches} />
      <TableContainer sx={{ width: '100%', maxHeight: window.innerHeight - HEADER_HEIGHT - 20 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.root}>
              {COLUMNS.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: { xs: 10, md: 12, lg: 14, xl: 18 },
                  }}
                >
                  {column}
                </TableCell>
              ))}
              <TableCell
                style={{
                  wordBreak: 'break-word',
                }}
                sx={{
                  fontSize: cellFont,
                }}
              >
                {window.innerWidth <= 800 ? (
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      setMatchToEdit(undefined);
                      setTimeout(() => {
                        setOpen(true);
                      }, 100);
                    }}
                  >
                    <AddIcon sx={{ color: 'white' }} />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{ color: 'white', borderColor: 'white', fontSize: cellFont }}
                    startIcon={<AddIcon sx={{ color: 'white' }} />}
                    onClick={() => {
                      setMatchToEdit(undefined);
                      setTimeout(() => {
                        setOpen(true);
                      }, 100);
                    }}
                  >
                    Add Match
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches && matches?.length > 0 ? (
              matches?.map(match => (
                <TableRow hover role="checkbox" tabIndex={-1} key={match.id}>
                  <TableCell
                    style={{
                      wordBreak: 'break-word',
                    }}
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    {match.team_a}
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: 'break-word',
                    }}
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    {match.team_b}
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: 'break-word',
                    }}
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    {formatDate(match.match_date)}
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: 'break-word',
                    }}
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    {formatTime(match.match_time)}
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: 'break-word',
                    }}
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    {match.stage}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: cellFont,
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setMatchToEdit(match);
                        setOpen(true);
                      }}
                      style={{
                        wordBreak: 'break-word',
                      }}
                      sx={{
                        color: '#0D92F4',
                        fontSize: cellFont,
                      }}
                    >
                      <EditIcon sx={{ fontSize: { xs: 10, md: 12, lg: 18, xl: 18 } }} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      sx={{ color: 'red', fontSize: cellFont }}
                      onClick={() => deleteMatch(match.id).then(() => refetchMatches())}
                    >
                      <DeleteIcon sx={{ fontSize: { xs: 10, md: 12, lg: 18, xl: 18 } }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography variant="h6" component="div" sx={{ color: 'gray' }}>
                No matches scheduled.
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
