import { IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { ADD_SCORER_PAYLOAD, TOP_SCORER, useAddScorerMutation, useUpdateTopScorerMutation } from '../../redux/api/tsfnaApi';

//icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TEAMS } from '../../global-constants/constants';

const modalWidth = window.innerWidth > 800 ? 600 : window.innerWidth - 10;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: modalWidth,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

// interfaces
interface AddEditMatchModalProps {
  player?: TOP_SCORER;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  refetch: () => void;
}
export default function ScorerModal({ player, open, setOpen, refetch }: AddEditMatchModalProps) {
  // Hooks
  const [addScorer] = useAddScorerMutation();
  const [updateScorer] = useUpdateTopScorerMutation();
  const [playerName, setPlayerName] = useState('');
  const [team, setTeam] = useState('');
  const [goals, setGoals] = useState<number>(0);
  const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (team.length === 0) {
      setWarningMessage('Please select team');
    } else if (playerName.length === 0) {
      setWarningMessage('Please enter player name');
    } else if (goals === undefined) {
      setWarningMessage("Please enter player's goals");
    } else {
      setWarningMessage(undefined);
    }
  }, [team, playerName, goals]);

  useEffect(() => {
    if (player) {
      setPlayerName(player.player);
      setTeam(player.team);
      setGoals(player.goals);
    }
  }, [player]);

  const reset = () => {
    setPlayerName('');
    setTeam('');
    setGoals(0);
    setWarningMessage(undefined);
  };

  const hasChanged = () => {
    if (player) {
      return player.player !== playerName || player.team !== team || player.goals !== goals;
    }
    return playerName.length > 0 && team.length > 0 && goals !== 0;
  };
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Stack direction="column" spacing={3} sx={style}>
          <Stack direction="row" spacing={5} sx={{ paddingTop: 10 }}>
            <TextField
              id="outlined-team-input"
              select
              label="Team"
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              value={team}
              onChange={e => setTeam(e.target.value)}
            >
              {TEAMS.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-team-a-input"
              label="Name"
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              value={playerName}
              onChange={e => setPlayerName(e.target.value)}
            />
          </Stack>

          <Stack direction="row">
            <IconButton
              disabled={goals === 0}
              aria-label="decrease goals"
              onClick={() => {
                if (goals && goals > 0) {
                  setGoals(goals - 1);
                }
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
            <TextField
              id="outlined-team-a-input"
              label={`Goals(${player?.goals || 0})`}
              value={goals}
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <IconButton
              aria-label="increase played"
              onClick={() => {
                if (goals !== undefined) {
                  setGoals(goals + 1);
                }
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Stack>
          {warningMessage && (
            <Stack
              direction="row"
              spacing={1}
              paddingTop={{ xs: 5, md: 5, lg: 8, xl: 10 }}
              sx={{ color: 'red', display: 'flex', alignItems: 'center' }}
            >
              <InfoIcon sx={{ color: 'gray', fontSize: '16px' }} />
              <Typography variant="subtitle2" component="div" sx={{ fontSize: '12px' }}>
                {warningMessage}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={10} paddingTop={warningMessage ? 0 : { xs: 5, md: 5, lg: 8, xl: 10 }}>
            <Button
              variant="contained"
              sx={{ bgcolor: 'gray' }}
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!hasChanged()}
              onClick={() => {
                if (player) {
                  const updatedScorer: TOP_SCORER = {
                    ...player,
                    player: playerName,
                    team: team,
                    goals: goals,
                  };
                  updateScorer({ scorer: updatedScorer }).then(() => refetch());
                } else {
                  // new scorer
                  const newScorer: ADD_SCORER_PAYLOAD = {
                    player: playerName,
                    team: team,
                    goals: goals,
                  };
                  addScorer(newScorer).then(() => refetch());
                }
                reset();
                setOpen(false);
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
