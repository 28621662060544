import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetMatchesQuery, useGetTeamStandingsQuery, useGetTopScorersQuery } from '../../redux/api/tsfnaApi';
import { formatDateShort } from '../../utils/utils';
import { groupMatchesByStage, groupStandingsByGroup } from '../admin/adminUtils'; // Ensure this is a function
import CustomToggle from './CustomToggle';
import MatchCardContainer from './MatchCardContainer';
import TeamStanding from './TeamStanding';
import TopScorers from './TopScorers';

const TournamentContainer = () => {
  // Hooks
  const { data: matches } = useGetMatchesQuery();
  const { data: standings } = useGetTeamStandingsQuery();
  const { data: topScorers } = useGetTopScorersQuery();
  const [selectedTab, setSelectedTab] = useState('matches');
  const [groupedMatches, setGroupedMatches] = useState(groupMatchesByStage(matches || []));
  const [groupedStandings, setGroupedStandings] = useState(groupStandingsByGroup(standings || []));
  const [scorers, setScorers] = useState(topScorers || []);

  useEffect(() => {
    if (matches) {
      setGroupedMatches(groupMatchesByStage(matches));
    }

    if (standings) {
      setGroupedStandings(groupStandingsByGroup(standings));
    }

    if (topScorers) {
      setScorers(topScorers);
    }
  }, [matches, standings, topScorers]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: window.innerHeight - 296,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        marginTop: 5,
      }}
    >
      <CustomToggle selected={selectedTab} setSelected={setSelectedTab} />
      {selectedTab === 'standings' ? (
        <Stack direction={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }} spacing={2} mt={5} mb={50}>
          {standings && Object.keys(groupedStandings).length > 0 ? (
            Object.keys(groupedStandings).map((group, index) => (
              <TeamStanding key={index} group={`Group ${group}`} teamsStanding={groupedStandings[group]} />
            ))
          ) : (
            <Typography sx={{ fontSize: 14, color: 'gray', mt: 20 }}>Standings will be shown here.</Typography>
          )}
        </Stack>
      ) : selectedTab === 'stats' ? (
        <Stack direction={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }} spacing={2} mt={5} mb={50}>
          {scorers && scorers.length > 0 ? (
            <TopScorers title="Top Scorers" players={scorers} />
          ) : (
            <Typography sx={{ fontSize: 14, color: 'gray', mt: 20 }}>Top scorers will be shown here.</Typography>
          )}
        </Stack>
      ) : (
        <>
          {matches && Object.keys(groupedMatches).length > 0 ? (
            <>
              {Object.keys(groupedMatches).map((stage, index) => (
                <MatchCardContainer
                  key={index}
                  text={`${stage}. ${formatDateShort(groupedMatches[stage][0].match_date)}`}
                  matches={groupedMatches[stage]}
                />
              ))}
            </>
          ) : (
            <Typography sx={{ fontSize: 14, color: 'gray', mt: 20 }}>Matches schedule will appear here </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default TournamentContainer;
