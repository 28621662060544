import { IconButton, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import { STANDING, useUpdateTeamStandingsMutation } from '../../redux/api/tsfnaApi';

//icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const modalWidth = window.innerWidth > 800 ? 600 : window.innerWidth - 10;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: modalWidth,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

// interfaces
interface MatchResultModalProps {
  standing: STANDING | undefined;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  refetch: () => void;
}
export default function StandingsModal({ standing, open, setOpen, refetch }: MatchResultModalProps) {
  // Hooks
  const [updateStanding] = useUpdateTeamStandingsMutation();
  const [played, setPlayed] = useState(standing?.played);
  const [goals, setGoals] = useState(standing?.goals);
  const [points, setPoints] = useState(standing?.points);

  useEffect(() => {
    if (standing) {
      setPlayed(standing.played);
      setGoals(standing.goals);
      setPoints(standing.points);
    }
  }, [standing]);

  const hasChanged = () => {
    return standing?.played !== played || standing?.goals !== goals || standing?.points !== points;
  };
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Stack direction="column" spacing={5} sx={style}>
          <Typography variant="h5">{standing?.team}</Typography>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack direction="row">
              <IconButton
                disabled={played === 0}
                aria-label="decrease played"
                onClick={() => {
                  if (played && played > 0) {
                    setPlayed(played - 1);
                  }
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
              <TextField
                id="outlined-team-a-input"
                label={`Played(${standing?.played})`}
                value={played}
                type="text"
                sx={{ width: { xs: modalWidth * 0.3, md: modalWidth * 0.3, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <IconButton
                aria-label="increase played"
                onClick={() => {
                  if (played !== undefined) {
                    setPlayed(played + 1);
                  }
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Stack>
            <Stack direction="row">
              <IconButton
                aria-label="decrease goals"
                onClick={() => {
                  if (goals !== undefined) {
                    setGoals(goals - 1);
                  }
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
              <TextField
                id="outlined-team-a-input"
                label={`Goals(${standing?.goals})`}
                value={goals}
                type="text"
                sx={{ width: { xs: modalWidth * 0.3, md: modalWidth * 0.3, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <IconButton
                aria-label="increase goals"
                onClick={() => {
                  if (goals !== undefined) {
                    setGoals(goals + 1);
                  }
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row">
            <IconButton
              aria-label="decrease points"
              onClick={() => {
                if (points !== undefined) {
                  setPoints(points - 1);
                }
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
            <TextField
              id="outlined-team-a-input"
              label={`Points(${standing?.points})`}
              value={points}
              type="text"
              sx={{ width: { xs: modalWidth * 0.3, md: modalWidth * 0.3, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <IconButton
              aria-label="increase points"
              onClick={() => {
                if (points !== undefined) {
                  setPoints(points + 1);
                }
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={10} paddingTop={10}>
            <Button
              variant="contained"
              sx={{ bgcolor: 'gray' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!hasChanged()}
              onClick={() => {
                if (standing && played !== undefined && goals !== undefined && points !== undefined) {
                  const updatedStanding = { ...standing, played, goals, points };
                  updateStanding({ standing: updatedStanding }).then(() => {
                    refetch();
                    setOpen(false);
                  });
                }
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
