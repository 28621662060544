import { IconButton, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { MATCH, useUpdateMatchMutation } from '../../redux/api/tsfnaApi';

//icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const modalWidth = window.innerWidth > 800 ? 600 : window.innerWidth - 10;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: modalWidth,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

// interfaces
interface MatchResultModalProps {
  match?: MATCH;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  refetch: () => void;
}
export default function MatchResultModal({ match, open, setOpen, refetch }: MatchResultModalProps) {
  const extractResult = (result: string | undefined) => {
    if (!result) {
      return [0, 0];
    }
    const res = result.split(':');
    return [parseInt(res[0]), parseInt(res[1])];
  };

  // Hooks
  const [updateMatch] = useUpdateMatchMutation();

  const [teamAResult, setTeamAResult] = useState(extractResult(match?.result)[0]);
  const [teamBResult, setTeamBResult] = useState(extractResult(match?.result)[0]);

  useEffect(() => {
    if (match) {
      const res = extractResult(match.result);
      setTeamAResult(res[0]);
      setTeamBResult(res[1]);
    }
  }, [match]);

  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Stack direction="column" spacing={3} sx={style}>
          <Stack direction="row" spacing={{ xs: 5, md: 5, lg: 10, xl: 10 }} sx={{ paddingTop: 10 }}>
            <TextField
              id="outlined-team-a-input"
              label={match?.team_a}
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              disabled
            />
            <TextField
              id="outlined-team-a-input"
              label={match?.team_b}
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              disabled
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack direction="row">
              <IconButton
                disabled={teamAResult === 0}
                aria-label="decrease team A goal"
                onClick={() => {
                  if (teamAResult > 0) {
                    setTeamAResult(teamAResult - 1);
                  }
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
              <IconButton aria-label="increase team A goal" onClick={() => setTeamAResult(teamAResult + 1)}>
                <AddCircleIcon />
              </IconButton>
            </Stack>
            <Typography>
              {teamAResult} : {teamBResult}
            </Typography>
            <Stack direction="row">
              <IconButton aria-label="increase team B goal" onClick={() => setTeamBResult(teamBResult + 1)}>
                <AddCircleIcon />
              </IconButton>
              <IconButton
                disabled={teamBResult === 0}
                aria-label="increase team B goal"
                onClick={() => {
                  if (teamBResult > 0) {
                    setTeamBResult(teamBResult - 1);
                  }
                }}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={10} paddingTop={10}>
            <Button
              variant="contained"
              sx={{ bgcolor: 'gray' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (match) {
                  const updatedMatch = { ...match, result: `${teamAResult} : ${teamBResult}` };
                  updateMatch({ id: match.id, match: updatedMatch }).then(() => {
                    refetch();
                    setOpen(false);
                  });
                }
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
