import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { ADD_MATCH_PAYLOAD, MATCH, STAGES, useAddMatchMutation, useUpdateMatchMutation } from '../../redux/api/tsfnaApi';

//icons
import InfoIcon from '@mui/icons-material/Info';
import { TEAMS, TOURNAMENT_STAGES } from '../../global-constants/constants';

const modalWidth = window.innerWidth > 800 ? 600 : window.innerWidth - 10;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: modalWidth,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  bgColor: 'red',
};

// interfaces
interface AddEditMatchModalProps {
  match?: MATCH;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  refetch: () => void;
}

export default function AddEditMatchModal({ match, open, setOpen, refetch }: AddEditMatchModalProps) {
  // Hooks
  const [addMatch] = useAddMatchMutation();
  const [updateMatch] = useUpdateMatchMutation();
  const [teamA, setTeamA] = useState('');
  const [teamB, setTeamB] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [matchTime, setMatchTime] = useState('');
  const [stage, setStage] = useState(STAGES.GROUP_STAGE);
  const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (teamA.length === 0) {
      setWarningMessage('Please select team A');
    } else if (teamB.length === 0) {
      setWarningMessage('Please select team B');
    } else if (matchDate.length === 0) {
      setWarningMessage('Please set match date');
    } else if (matchTime.length === 0) {
      setWarningMessage('Please set match time');
    } else {
      setWarningMessage(undefined);
    }
  }, [teamA, teamB, matchDate, matchTime]);

  useEffect(() => {
    if (match) {
      setTeamA(match.team_a);
      setTeamB(match.team_b);
      setMatchDate(match.match_date);
      setMatchTime(match.match_time);
      setStage(match.stage);
    }
  }, [match]);

  const reset = () => {
    setTeamA('');
    setTeamB('');
    setMatchDate('');
    setMatchTime('');
    setStage(STAGES.GROUP_STAGE);
  };

  const validateMatch = () => {
    return teamA.length > 0 && teamB.length > 0 && matchDate.length > 0 && matchTime.length > 0;
  };

  const matchEdited = () => {
    if (!match) {
      return true;
    }
    return (
      teamA !== match.team_a ||
      teamB !== match.team_b ||
      matchDate !== match.match_date ||
      matchTime !== match.match_time ||
      stage !== match.stage
    );
  };
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack direction="column" spacing={3} sx={style}>
        <Stack direction={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }} spacing={2} sx={{ paddingTop: 5 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              id="outlined-team-a-input"
              select
              label="Team A"
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              value={teamA}
              onChange={e => setTeamA(e.target.value)}
            >
              {TEAMS.map(
                (option, index) =>
                  option.value !== teamB && (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
              )}
            </TextField>
            <TextField
              id="outlined-team-a-input"
              select
              label="Team B"
              type="text"
              sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
              value={teamB}
              onChange={e => setTeamB(e.target.value)}
            >
              {TEAMS.map(
                (option, index) =>
                  option.value !== teamA && (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
              )}
            </TextField>
          </Stack>
          <TextField
            id="outlined-stage-input"
            select
            label="Stage"
            type="text"
            sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
            value={stage}
            onChange={e => setStage(e.target.value as STAGES)}
          >
            {TOURNAMENT_STAGES.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction="row" spacing={2}>
          <TextField
            id="outlined-match-date-input"
            type="date"
            sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
            value={matchDate}
            onChange={e => setMatchDate(e.target.value)}
          />
          <TextField
            id="outlined-match-time-input"
            type="time"
            sx={{ width: { xs: modalWidth * 0.4, md: modalWidth * 0.4, lg: modalWidth * 0.3, xl: modalWidth * 0.3 } }}
            value={matchTime}
            onChange={e => setMatchTime(e.target.value)}
          />
        </Stack>
        {warningMessage && (
          <Stack
            direction="row"
            spacing={1}
            paddingTop={{ xs: 3, md: 3, lg: 10, xl: 10 }}
            sx={{ color: 'red', display: 'flex', alignItems: 'center' }}
          >
            <InfoIcon sx={{ color: 'gray', fontSize: '16px' }} />
            <Typography variant="subtitle2" component="div" sx={{ fontSize: '12px' }}>
              {warningMessage}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={10} paddingTop={warningMessage ? 0 : { xs: 3, md: 3, lg: 10, xl: 10 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: 'gray' }}
            onClick={() => {
              setOpen(false);
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!validateMatch() || !matchEdited()}
            onClick={() => {
              if (validateMatch()) {
                let newMatch: ADD_MATCH_PAYLOAD = {
                  team_a: teamA,
                  team_b: teamB,
                  match_date: matchDate,
                  match_time: matchTime,
                  result: undefined,
                  stage: stage,
                };

                if (match) {
                  // edit match
                  const editedMatch: MATCH = {
                    id: match.id,
                    ...newMatch,
                  };
                  updateMatch({ id: match.id, match: editedMatch })
                    .then(() => {
                      refetch();
                    })
                    .catch(err => console.log('Error while adding new match', err));
                } else {
                  // add new match
                  addMatch(newMatch)
                    .then(() => {
                      refetch();
                    })
                    .catch(err => console.log('Error while adding new match', err));
                }
                reset();
                setOpen(false);
              }
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
