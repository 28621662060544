import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { Theme } from '../../global-constants/Theme';

interface TournamentScheduleProps {
  selected: string;
  setSelected: (val: string) => void;
}

const CustomToggle = ({ selected, setSelected }: TournamentScheduleProps) => {
  const cardWidth = (window.innerWidth - 10) / 3;
  return (
    <ToggleButtonGroup exclusive aria-label="Tournament schedule" sx={{ marginTop: 10 }}>
      <ToggleButton
        value="web"
        selected={selected === 'matches'}
        onClick={() => setSelected('matches')}
        sx={{
          borderRadius: 10,
          width: cardWidth,
          textTransform: 'none',
          maxWidth: 400,
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        Matches
      </ToggleButton>
      <ToggleButton
        value="android"
        selected={selected === 'standings'}
        onClick={() => setSelected('standings')}
        sx={{
          borderRadius: 10,
          textTransform: 'none',
          width: cardWidth,
          maxWidth: 400,
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        Standings
      </ToggleButton>
      <ToggleButton
        value="android"
        selected={selected === 'stats'}
        onClick={() => setSelected('stats')}
        sx={{
          borderRadius: 10,
          textTransform: 'none',
          width: (window.innerWidth - 10) / 3,
          maxWidth: 400,
          '&&.Mui-selected': {
            backgroundColor: Theme.orange,
          },
        }}
      >
        Stats
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CustomToggle;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#00ff00',
  },
});
