import EditIcon from '@mui/icons-material/Edit';
import { alpha, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Theme } from '../../global-constants/Theme';
import { MATCH } from '../../redux/api/tsfnaApi';
import { HEADER_HEIGHT } from '../header/Header';
import MatchResultModal from './MatchResultModal';

const useStyles = makeStyles({
  root: {
    '& .MuiTableCell-head': {
      color: 'white',
      backgroundColor: alpha(Theme.orange, 0.8),
    },
  },
});

export const COLUMNS = ['Matches', 'Results', ''];

// interfaces
interface MatchResultsProps {
  matches: MATCH[] | undefined;
  refetchMatches: () => void;
}

export default function MatchResults({ matches, refetchMatches }: MatchResultsProps) {
  const width = window.innerWidth > 800 ? 980 : window.innerWidth - 10;

  // Hooks
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [matchToEdit, setMatchToEdit] = useState<MATCH | undefined>(undefined);
  return (
    <Box sx={{ width: width, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          marginTop: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MatchResultModal match={matchToEdit} open={open} setOpen={setOpen} refetch={refetchMatches} />
        <TableContainer sx={{ width: '100%', maxHeight: window.innerHeight - HEADER_HEIGHT - 20 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.root}>
                {COLUMNS.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {matches && matches?.length > 0
                ? matches?.map(match => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={match.id}>
                      <TableCell>
                        {match.team_a} <span style={{ color: '#0D92F4', fontWeight: 'bold' }}> vs </span> {match.team_b}
                      </TableCell>
                      <TableCell>{match.result ?? '0 : 0'}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          sx={{ color: '#0D92F4' }}
                          onClick={() => {
                            setMatchToEdit(match);
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
